export const bottomFooterLinks = {
  en: {
    "vantage-circle": "/",
    "dpdp" : "/digital-personal-data-protection-dpdp-act-rights/",
    gdpr: "/gdpr/",
    "security-at-vantage-circle": "/security-at-vantage-circle/",
    "terms-conditions": "/terms-conditions/",
    "privacy-policy": "/privacy-policy/",
    "image-copyright": "/image-copyright/",
    "cookie-policy":
      "https://res.cloudinary.com/vantagecircle/image/upload/v1730712841/VC-BLOG/Vantagecircle_Cookie_Policy.docx_2.pdf",
  },
  fr: {
    "vantage-circle": "/fr/",
    "dpdp" : "/digital-personal-data-protection-dpdp-act-rights/",
    gdpr: "/fr/gdpr-french/",
    "security-at-vantage-circle": "/fr/securite/",
    "terms-conditions": "/fr/conditions-generales/",
    "privacy-policy": "/fr/confidentialite-politique/",
    "image-copyright": "/fr/image-droit-auteur/",
    "cookie-policy":
      "https://res.cloudinary.com/vantagecircle/image/upload/v1730712841/VC-BLOG/Vantagecircle_Cookie_Policy.docx_2.pdf",
  },
  es: {
    "vantage-circle": "/es/",
    "dpdp" : "/digital-personal-data-protection-dpdp-act-rights/",
    gdpr: "/es/gdpr-spanish/",
    "security-at-vantage-circle": "/es/seguridad/",
    "terms-conditions": "/es/terminos-condiciones/",
    "privacy-policy": "/es/politica-privacidad/",
    "image-copyright": "/es/derechos-autor-imagen/",
    "cookie-policy":
      "https://res.cloudinary.com/vantagecircle/image/upload/v1730712841/VC-BLOG/Vantagecircle_Cookie_Policy.docx_2.pdf",
  },
  de: {
    "vantage-circle": "/de/",
    "dpdp" : "/digital-personal-data-protection-dpdp-act-rights/",
    gdpr: "/de/gdpr-german/",
    "security-at-vantage-circle": "/de/sicherheit-im-vantage-circle/",
    "terms-conditions": "/de/geschaeftsbedingungen/",
    "image-copyright": "/de/bildrechte/",
    "privacy-policy": "/de/datenschutzerklarung/",
    "cookie-policy":
      "https://res.cloudinary.com/vantagecircle/image/upload/v1730712841/VC-BLOG/Vantagecircle_Cookie_Policy.docx_2.pdf",
  },
  // 'nl' : {
  //     'security-at-vantage-circle' : '/nl/beveiliging-bij-vantage-circle/',
  //     'gdpr': '/nl/gdpr-dutch/',
  //     'terms-conditions' : '/nl/voorwaarden-condities/',
  //     'image-copyright' : '/nl/afbeelding-copyright/',
  //     'privacy-policy' : '/nl/privacybeleid/',
  //     'vantage-circle' : '/nl/',
  //     'cookie-policy' : 'https://res.cloudinary.com/vantagecircle/image/upload/v1710505922/vantagecircle/Vantagecircle_Cookie_Policy.docx.pdf'
  // },
  // 'pt' : {
  //     'security-at-vantage-circle' : '/pt/seguranca-na-vantage-circle/',
  //     'gdpr': '/pt/gdpr-portuguese/',
  //     'terms-conditions' : '/pt/termos-e-condicoes/',
  //     'privacy-policy' : '/pt/politica-de-privacidade/',
  //     'image-copyright' : '/pt/direitos-autorais-da-imagem/',
  //     'cookie-policy' : 'https://res.cloudinary.com/vantagecircle/image/upload/v1710505922/vantagecircle/Vantagecircle_Cookie_Policy.docx.pdf'
  // }
}

export const footerLinks = {
  en: {
    "about-us": "/about-us/",
    careers: "/careers/",
    "advertise-with-vantage-circle": "/advertise-with-vantage-circle/",
    "strategic-partnership": "/strategic-partnership/",
    "in-the-press": "/in-the-press/",
    "contact-us": "/contact-us/",
    "rewards-recognition": "/products/rewards-recognition/",
    "employee-discount": "/products/employee-discount/",
    "employee-survey": "/products/employee-survey/",
    "employee-engagement-software": "/employee-engagement-software/",
    integration: "/integration/",
    "hr-academy": "/hr-academy/",
  },
  fr: {
    "about-us": "/fr/a-propos-de-nous/",
    "advertise-with-vantage-circle": "/fr/annoncez-vantage-circle/",
    "strategic-partnership": "/fr/partenariat-strategique/",
    donwload: "/fr/application-telecharger/",
    "contact-us": "/fr/contactez-nous/",
    integration: "/fr/solutions-dintegration/",
    "rewards-recognition":
      "/fr/logiciel-engagement-employes/recompenses-reconnaissance/",
    "employee-discount": "/fr/logiciel-engagement-employes/employe-avantages/",
    "employee-survey": "/fr/logiciel-engagement-employes/employe-sondage/",
    "employee-engagement-software": "/fr/logiciel-engagement-employes/",
  },
  es: {
    "about-us": "/es/sobre-nosotros/",
    "advertise-with-vantage-circle": "/es/anunciate-con-nosotros/",
    "strategic-partnership": "/es/asociacion-estrategica/",
    download: "/es/descargar-aplicacion/",
    "contact-us": "/es/contactenos/",
    integration: "/es/integracion/",
    "rewards-recognition":
      "/es/software-participacion-empleados/reconocimiento-recompensas/",
    "employee-discount":
      "/es/software-participacion-empleados/beneficios-empleados/",
    "employee-survey":
      "/es/software-participacion-empleados/encuesta-empleados/",
    "employee-engagement-software": "/es/software-participacion-empleados/",
  },
  de: {
    "about-us": "/de/uber-uns/",
    "advertise-with-vantage-circle": "/de/werben-sie-mit-uns/",
    "strategic-partnership": "/de/strategischer-partner/",
    download: "/de/app-herunterladen/",
    "contact-us": "/de/kontaktiere-uns/",
    integration: "/de/integrationslosungen/",
    "rewards-recognition": "/de/alles-in-einem/mitarbeiter-belohnungen/",
    "employee-discount": "/de/alles-in-einem/miarbeiterrabatt/",
    "employee-survey": "/de/alles-in-einem/mitarbeiterpuls/",
    "employee-engagement-software": "/de/alles-in-einem/",
  },
  // 'nl' : {
  //     'about-us' : '/nl/over-ons/',
  //     'advertise-with-vantage-circle': '/nl/adverteren-bij-vantage-circle/',
  //     'strategic-partnership' : '/nl/strategisch-partnerchap/',
  //     'donwload' : '/nl/app-downloaden/',
  //     'contact-us' : '/nl/contacteer-ons/',
  //     'integration' : '/nl/integraties/',
  //     'rewards-recognition': '/nl/software-voor-werknemersbetrokkenheid/werknemersbeloningen/',
  //     'employee-discount': '/nl/software-voor-werknemersbetrokkenheid/werknemerskorting/',
  //     'employee-survey': '/nl/software-voor-werknemersbetrokkenheid/medewerkersonderzoek/',
  //     'employee-engagement-software': '/nl/software-voor-werknemersbetrokkenheid/',
  // },
  // 'pt' : {
  //     'about-us' : '/pt/sobre-nos/',
  //     'advertise-with-vantage-circle': '/pt/anuncie-conosco/',
  //     'strategic-partnership' : '/pt/parceria-estrategica/',
  //     'donwload' : '/pt/baixe-o-app/',
  //     'contact-us' : '/pt/contate-nos/',
  //     'integration' : '/pt/integracao/',
  //     'rewards-recognition': '/pt/software-de-engajamento-de-funcionarios/recompensas-reconhecimento/',
  //     'employee-discount': '/pt/software-de-engajamento-de-funcionarios/desconto-de-funcionario/',
  //     'employee-survey': '/pt/software-de-engajamento-de-funcionarios/pulso-do-funcionario/',
  //     'employee-engagement-software': '/pt/software-de-engajamento-de-funcionarios/',
  // },
}
