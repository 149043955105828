 // import { useLabels } from "@headlessui/react/dist/components/label/label";
import React from "react";
// import {Trans} from 'react-i18next';
// import { useI18next } from 'gatsby-plugin-react-i18next';
import { footerLinks } from "../../../static/footerLinks";
import { useLanguages } from "../../hooks/useLanguages";
import Trans from '../Trans'
import { Link } from "gatsby";

const FooterMultiLang = ({pageLanguage}) => {

    const getUrl = (urlKey) => {
        if(pageLanguage === undefined) return
        return footerLinks[pageLanguage][urlKey]
    };

    const [currLanguage] = useLanguages()

    return (
        <>
            <div className="m-auto text-gray-800 flex flex-wrap justify-left md:pb-10">
                <div className="w-full sm:w-1/2 md:w-1/2">
                    <div className="text-md text-white mt-5 md:mt-0 md:mb-6">
                        <Trans pageLanguage={pageLanguage}>Quick Links</Trans>
                    </div>
                    <a href={getUrl('about-us')} className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        <Trans pageLanguage={pageLanguage}>About us</Trans>
                    </a>
                    <a href={getUrl('advertise-with-vantage-circle')} className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        <Trans pageLanguage={pageLanguage}>Advertise with us</Trans>
                    </a>
                    <a href={getUrl('strategic-partnership')} className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        <Trans pageLanguage={pageLanguage}>Partner with us</Trans>
                    </a>
                    <a href={getUrl('download')} className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        <Trans pageLanguage={pageLanguage}>Download app</Trans>
                    </a>
                    <a href={getUrl('contact-us')} className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        <Trans pageLanguage={pageLanguage}>Contact us</Trans>
                    </a>
                    <a href={getUrl('integration')} className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        <Trans pageLanguage={pageLanguage}>Integration</Trans>
                    </a>
                    
                </div>
                <div className="w-full sm:w-1/2 md:w-1/2">
                    <div className="text-md text-white mt-5 md:mt-0 md:mb-6">
                        <Trans pageLanguage={pageLanguage}>Solutions</Trans>
                    </div>
                    <a href={getUrl('rewards-recognition')} className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        <Trans pageLanguage={pageLanguage}>Employee Recognition</Trans>
                    </a>
                    <a href={getUrl('employee-discount')} className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        <Trans pageLanguage={pageLanguage}>Employee Discount</Trans>
                    </a>
                    <a href={getUrl('employee-survey')} className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        <Trans pageLanguage={pageLanguage}>Employee Survey</Trans>
                    </a>
                    <a href="https://www.vantagefit.io/" target="_blank"  rel="noreferrer" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        <Trans pageLanguage={pageLanguage}>Employee Wellness</Trans>
                    </a>
                    <a href={getUrl('employee-engagement-software')} className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        <Trans pageLanguage={pageLanguage}>All-in-One Solution</Trans>
                    </a>
                </div>    
            </div>
        </>
    );
}

export default FooterMultiLang;