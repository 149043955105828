import React from "react";
import { Link } from "gatsby";

const FooterEn = () => {

    return (
        <>
            <div className="m-auto text-gray-800 flex flex-wrap justify-left md:pb-10">
                <div className="w-full sm:w-1/2 md:w-3/12">
                    <div className="text-md text-white mt-5 md:mt-0 md:mb-6">
                        Quick Links
                    </div>
                    <a href="/about-us/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        About us
                    </a>
                    <a href="/careers/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Careers - <span className="text-orange">We're hiring!</span>
                    </a>
                    <a href="/advertise-with-vantage-circle/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Advertise with us
                    </a>
                    <a href="/strategic-partnership/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Partner with us
                    </a>
                    <a href="/in-the-press/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        In the News
                    </a>
                    <a href="https://www.vantagecircle.com/en/blog/become-a-contributor/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Become a Contributor
                    </a>
                    <a href="/contact-us/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Contact us
                    </a>
                    <a href="/refer-vantage-circle/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Affiliate Program
                    </a>
                    <a href="/about-us/sustainability/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Sustainability
                    </a>
                    
                </div>
                <div className="w-full sm:w-1/2 md:w-3/12">
                    <div className="text-md text-white mt-5 md:mt-0 md:mb-6">
                        Products
                    </div>
                    <a href="/products/rewards-recognition/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Employee Recognition
                    </a>
                    <a href="/products/employee-discount/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Employee Discount
                    </a>
                    <a href="/products/employee-survey/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Employee Survey
                    </a>
                    <a href="https://www.vantagefit.io/" rel="noopener noreferrer" target="_blank" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Employee Wellness
                    </a>
                    <a href="/employee-engagement-software/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        All-in-One Solution
                    </a>
                    <a href="/product-updates/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                    Product Updates
                    </a>
                    <a href="/competitors/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                    How Vantage Circle Compares
                    </a>
                </div>

                <div className="w-full sm:w-1/2 md:w-3/12">
                    <div className="text-md text-white mt-5 md:mt-0 md:mb-6">
                        Resources Center
                    </div>
                    <a href="https://www.vantagecircle.com/en/blog/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Blog
                    </a>
                    <a href="/integration/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Integration
                    </a>
                    <a href="/hr-academy/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        HR Academy
                    </a>
                    <a href="https://docs.vantagecircle.com/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Help Center
                    </a>
                </div>

                <div className="w-full sm:w-1/2 md:w-3/12">
                    <div className="text-md text-white mt-5 md:mt-0 md:mb-6">
                        Learn More
                    </div>
                    <a href="https://www.vantagecircle.com/en/blog/employee-recognition/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Employee Recognition
                    </a>
                    <a href="https://www.vantagecircle.com/en/blog/employee-engagement/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Employee Engagement
                    </a>
                    <a href="https://www.vantagecircle.com/en/blog/employee-rewards-and-recognition/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Rewards & Recognition
                    </a>
                    <a href="https://www.vantagecircle.com/en/blog/employee-experience/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Employee Experience
                    </a>
                    <a href="https://www.vantagecircle.com/en/blog/pulse-surveys/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Pulse Survey
                    </a>
                    <a href="https://www.vantagecircle.com/en/blog/employee-benefits/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Employee Benefits
                    </a>
                    <a href="https://www.vantagecircle.com/en/blog/employee-retention-strategies/" className="my-3 block text-gray-300 hover:text-gray-100 text-base duration-700">
                        Employee Retention
                    </a>
                    
                </div>
            </div>
        </>
    );
}


export default FooterEn;