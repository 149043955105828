import React, { useState, useEffect } from "react"
import * as styles from "./styles.module.css"
import useGeoLocation from "../../hooks/useGeoLocation"
import { gdprCountries } from "../../../static/constants"
import Trans from "../Trans"
import ModalContainer from "../ModalContainer"

function CookieConsent({ pageLanguage, location }) {
  const [currentCountry] = useGeoLocation()

  const [showConsent, setShowConsent] = useState(false)

  const [showConsentPopup, setshowConsentPopup] = useState(false)

  const restrictedURLs = [
    "/thank-you-page-ads-platform/",
    "/thank-you-page-ads-program/",
    "/thank-you-for-aire-score-assessment-india/",
    "/v1/",
    "/advertise-with-us-v1/",
    "/advertise-with-us-v1"
  ]

  // const openCookieConsentPopUp = () => {
  //   setshowConsentPopup(true)
  // }

  // const closeConsentPopup = () => {
  //   setshowConsentPopup(false)
  // }

  const initialConsentState = () => {
    if (
      typeof window !== "undefined" &&
      !restrictedURLs.includes(window.location.pathname)
    ) {
      const localStorageConsent = localStorage.getItem("cookieConsentConfig")

      if (!gdprCountries.includes(currentCountry.country)) {
        if (localStorageConsent == null) {
          let initialObj = JSON.stringify({
            showConsentBar: true,
            allowCookie: true,
            country: currentCountry.country,
          })
          localStorage.setItem("cookieConsentConfig", initialObj)
          sessionStorage.setItem("allowGtm", "true")
          setShowConsent(true)
        } else {
          let storedState = JSON.parse(
            localStorage.getItem("cookieConsentConfig")
          )
          if (storedState.showConsentBar == true) {
            let initialObj = JSON.stringify({
              showConsentBar: true,
              allowCookie: true,
              country: currentCountry.country,
            })
            localStorage.setItem("cookieConsentConfig", initialObj)
            sessionStorage.setItem("allowGtm", "true")
            setShowConsent(true)
          } else setShowConsent(false)
        }
      }
    }
  }

  useEffect(() => {
    initialConsentState()
  }, [currentCountry.country])

  const handleEnableCookie = () => {
    let storedState = JSON.parse(localStorage.getItem("cookieConsentConfig"))
    storedState.showConsentBar = false
    storedState.allowCookie = true
    storedState.country = currentCountry.country
    localStorage.setItem("cookieConsentConfig", JSON.stringify(storedState))
    sessionStorage.setItem("allowGtm", "true")

    var evt = document.createEvent("StorageEvent")
    evt.initStorageEvent(
      "storage",
      false,
      false,
      "allowGtm",
      "oldValue",
      "newValue",
      null,
      window.localStorage
    )
    window.dispatchEvent(evt)
    setShowConsent(false)
  }

  const handleDisableCookie = () => {
    let storedState = JSON.parse(localStorage.getItem("cookieConsentConfig"))
    storedState.showConsentBar = false
    storedState.allowCookie = false
    storedState.country = currentCountry.country
    localStorage.setItem("cookieConsentConfig", JSON.stringify(storedState))
    sessionStorage.setItem("allowGtm", "false")

    var evt = document.createEvent("StorageEvent")
    evt.initStorageEvent(
      "storage",
      false,
      false,
      "allowGtm",
      "oldValue",
      "newValue",
      null,
      window.localStorage
    )
    window.dispatchEvent(evt) 
    window.location.reload()
    setShowConsent(false)
  }

  return (
    <>
      {showConsent ? (
        <>
          <div className={`${styles.consentWrapper}`}>
            <div
              className="bg-gray-500 rounded-full absolute h-7 w-7 -top-3 shadow-darkgray cursor-pointer flex place-content-center right-5"
              onClick={handleEnableCookie}
            >
              <p className="text-purple-100">X</p>
            </div>
            <div className="container flex justify-between">
              <div className="consent-content pt-5 md:pt-0 md:flex justify-between">
                <div className="consent-text pl-5">
                  <span className="text-xs md:text-base">
                    <Trans pageLanguage={pageLanguage}>ConsentTextHeader</Trans>
                  </span>
                  <br />
                  <span className="text-xs md:text-base md:pt-2">
                    <Trans pageLanguage={pageLanguage}>ConsentTextBody</Trans>
                    <a
                      href="https://res.cloudinary.com/vantagecircle/image/upload/v1710505922/vantagecircle/Vantagecircle_Cookie_Policy.docx.pdf"
                      className="text-orange"
                    >
                      &nbsp;Cookie Policy
                    </a>
                  </span>
                </div>
                <div className="consent-buttons md:m-3 mt-2 pr-5 text-center">
                  <button
                    className="mx-4 text-sm p-2 rounded bg-green mb-1 px-4"
                    style={{ width: "12rem" }}
                    onClick={handleEnableCookie}
                  >
                    <Trans pageLanguage={pageLanguage}>Allow Cookies</Trans>
                  </button>
                  <button
                    className="mx-4 text-sm p-2 rounded border border-gray-500 mt-1"
                    style={{ width: "12rem" }}
                    // onClick={handleDisableCookie}
                    onClick={handleDisableCookie}
                  >
                    <Trans pageLanguage={pageLanguage}>Decline Cookies</Trans>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* {showConsentPopup ? (
            <ModalContainer
              enableCookie={handleEnableCookie}
              closeCookieConsentPopup={closeConsentPopup}
              disableCookie={handleDisableCookie}
            />
          ) : null} */}
        </>
      ) : null}
    </>
  )
}

export default CookieConsent
