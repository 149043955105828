import React from "react"
import FooterEn from "./FooterSelect/FooterEn"
import FooterMultiLang from "./FooterSelect/FooterMultiLang"
import FooterBtmEn from "./FooterSelect/FooterBtmEn"
import Trans from "../components/Trans"
import { useLanguages } from "../hooks/useLanguages"
import CookieConsent from "./CookieConsent/CookieConsent"
// import { gdprCountries } from "../../static/constants"
import useGeoLocation from "../hooks/useGeoLocation"

const Footer = ({
  slantedTop = true,
  pageLanguage,
  showFooterContent = true,
}) => {
  const [originalPathObj] = useLanguages()

  const renderAppIcons = () => {
    return (
      <div className="container flex justify-center px-6 xl:px-0 2xl:px-6 pb-10">
        <div className="w-full 2xl:w-10/12">
          {handleFooter()}
          <div className="md:flex items-center justify-start md:justify-between lg:justify-start py-5 md:pb-10">
            <div className="">
              <div className="w-full md:flex md:ml-auto md:mr-0 md:mb-4 mx-auto items-center flex-shrink-0 md:space-x-2">
                <a
                  href="https://play.google.com/store/apps/details?id=com.bargaintechnologies.vantagecircle&hl=en"
                  className="mr-2 group inline-flex py-2 p-3 rounded items-center border border-gray-300 mb-1 md:mb-0"
                >
                  <svg fill="#bcbcc7" className="w-6 h-6" viewBox="0 0 512 512">
                    <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
                  </svg>
                  <span className="ml-4 flex items-start flex-col leading-none">
                    <span className="text-xs mb-1 text-gray-300 group-hover:text-gray-100 duration-700">
                      <Trans>FREE DOWNLOAD</Trans>
                    </span>
                    <span className="title-font font-bold text-gray-300 group-hover:text-gray-100 duration-700">
                      Google Play
                    </span>
                  </span>
                </a>
                <a
                  href="https://apps.apple.com/in/app/vantage-circle/id869311123?ign-mpt=uo%3D4"
                  className="group inline-flex py-2 p-3 rounded items-center border border-gray-300"
                >
                  <svg fill="#bcbcc7" className="w-6 h-6" viewBox="0 0 305 305">
                    <path d="M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z"></path>
                    <path d="M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z"></path>
                  </svg>
                  <span className="ml-4 flex items-start flex-col leading-none">
                    <span className="text-xs mb-1 text-gray-300 group-hover:text-gray-100 duration-700">
                      <Trans>FREE DOWNLOAD</Trans>
                    </span>
                    <span className="title-font font-bold text-gray-300 group-hover:text-gray-100 duration-700">
                      App Store
                    </span>
                  </span>
                </a>
              </div>
              <div className="flex social-icons -ml-3 mb-4">
                <a
                  href="https://www.facebook.com/vantagecircle/"
                  rel="noopener noreferrer"
                  className="w-8 h-8 flex items-end justify-center mr-2"
                >
                  <svg
                    className="flex-1 mx-auto"
                    height="20"
                    viewBox="0 0 24 24"
                    width="20"
                  >
                    <path
                      d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"
                      fill="#bcbcc7"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://twitter.com/VantageCircle/"
                  rel="noopener noreferrer"
                  className="w-8 h-8 flex items-end justify-center mr-2"
                >
                  <svg
                    className="flex-1 mx-auto"
                    height="20"
                    viewBox="0 0 24 24"
                    width="20"
                  >
                    <path
                      d="m21.534 7.113c.976-.693 1.797-1.558 2.466-2.554v-.001c-.893.391-1.843.651-2.835.777 1.02-.609 1.799-1.566 2.165-2.719-.951.567-2.001.967-3.12 1.191-.903-.962-2.19-1.557-3.594-1.557-2.724 0-4.917 2.211-4.917 4.921 0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.142-5.147-.424.737-.674 1.58-.674 2.487 0 1.704.877 3.214 2.186 4.089-.791-.015-1.566-.245-2.223-.606v.054c0 2.391 1.705 4.377 3.942 4.835-.401.11-.837.162-1.29.162-.315 0-.633-.018-.931-.084.637 1.948 2.447 3.381 4.597 3.428-1.674 1.309-3.8 2.098-6.101 2.098-.403 0-.79-.018-1.177-.067 2.18 1.405 4.762 2.208 7.548 2.208 8.683 0 14.342-7.244 13.986-14.637z"
                      fill="#bcbcc7"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/company/vantage-circle/"
                  rel="noopener noreferrer"
                  className="w-8 h-8 flex items-end justify-center mr-2"
                >
                  <svg
                    className="flex-1 mx-auto"
                    height="20"
                    viewBox="0 0 24 24"
                    width="20"
                  >
                    <path
                      d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z"
                      fill="#bcbcc7"
                    ></path>
                    <path
                      d="m.396 7.977h4.976v16.023h-4.976z"
                      fill="#bcbcc7"
                    ></path>
                    <path
                      d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z"
                      fill="#bcbcc7"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCzb6J4NEmhuGpj_-u2kz7aw/"
                  rel="noopener noreferrer"
                  className="w-8 h-8 flex items-end justify-center mr-2"
                >
                  <svg
                    className="flex-1 mx-auto"
                    height="20"
                    viewBox="0 0 24 24"
                    width="20"
                  >
                    <path
                      d="m23.469 5.929.03.196c-.29-1.029-1.073-1.823-2.068-2.112l-.021-.005c-1.871-.508-9.4-.508-9.4-.508s-7.51-.01-9.4.508c-1.014.294-1.798 1.088-2.083 2.096l-.005.021c-.699 3.651-.704 8.038.031 11.947l-.031-.198c.29 1.029 1.073 1.823 2.068 2.112l.021.005c1.869.509 9.4.509 9.4.509s7.509 0 9.4-.509c1.015-.294 1.799-1.088 2.084-2.096l.005-.021c.318-1.698.5-3.652.5-5.648 0-.073 0-.147-.001-.221.001-.068.001-.149.001-.23 0-1.997-.182-3.951-.531-5.846zm-13.861 9.722v-7.293l6.266 3.652z"
                      fill="#bcbcc7"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://www.pinterest.com/vantagecircle/"
                  rel="noopener noreferrer"
                  className="w-8 h-8 flex items-end justify-center mr-2"
                >
                  <svg
                    className="flex-1 mx-auto"
                    height="20"
                    viewBox="0 0 24 24"
                    width="20"
                  >
                    <path
                      d="m12.326 0c-6.579.001-10.076 4.216-10.076 8.812 0 2.131 1.191 4.79 3.098 5.633.544.245.472-.054.94-1.844.037-.149.018-.278-.102-.417-2.726-3.153-.532-9.635 5.751-9.635 9.093 0 7.394 12.582 1.582 12.582-1.498 0-2.614-1.176-2.261-2.631.428-1.733 1.266-3.596 1.266-4.845 0-3.148-4.69-2.681-4.69 1.49 0 1.289.456 2.159.456 2.159s-1.509 6.096-1.789 7.235c-.474 1.928.064 5.049.111 5.318.029.148.195.195.288.073.149-.195 1.973-2.797 2.484-4.678.186-.685.949-3.465.949-3.465.503.908 1.953 1.668 3.498 1.668 4.596 0 7.918-4.04 7.918-9.053-.016-4.806-4.129-8.402-9.423-8.402z"
                      fill="#bcbcc7"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/vantagecircle/"
                  rel="noopener noreferrer"
                  className="w-8 h-8 flex items-end justify-center"
                >
                  <svg
                    className="flex-1 mx-auto"
                    height="20"
                    viewBox="0 0 24 24"
                    width="20"
                  >
                    <path
                      d="m12.004 5.838c-3.403 0-6.158 2.758-6.158 6.158 0 3.403 2.758 6.158 6.158 6.158 3.403 0 6.158-2.758 6.158-6.158 0-3.403-2.758-6.158-6.158-6.158zm0 10.155c-2.209 0-3.997-1.789-3.997-3.997s1.789-3.997 3.997-3.997 3.997 1.789 3.997 3.997c.001 2.208-1.788 3.997-3.997 3.997z"
                      fill="#bcbcc7"
                    ></path>
                    <path
                      d="m16.948.076c-2.208-.103-7.677-.098-9.887 0-1.942.091-3.655.56-5.036 1.941-2.308 2.308-2.013 5.418-2.013 9.979 0 4.668-.26 7.706 2.013 9.979 2.317 2.316 5.472 2.013 9.979 2.013 4.624 0 6.22.003 7.855-.63 2.223-.863 3.901-2.85 4.065-6.419.104-2.209.098-7.677 0-9.887-.198-4.213-2.459-6.768-6.976-6.976zm3.495 20.372c-1.513 1.513-3.612 1.378-8.468 1.378-5 0-7.005.074-8.468-1.393-1.685-1.677-1.38-4.37-1.38-8.453 0-5.525-.567-9.504 4.978-9.788 1.274-.045 1.649-.06 4.856-.06l.045.03c5.329 0 9.51-.558 9.761 4.986.057 1.265.07 1.645.07 4.847-.001 4.942.093 6.959-1.394 8.453z"
                      fill="#bcbcc7"
                    ></path>
                    <circle
                      cx="18.406"
                      cy="5.595"
                      r="1.439"
                      fill="#bcbcc7"
                    ></circle>
                  </svg>
                </a>
              </div>
            </div>

            <div className="w-1/2 md:w-3/12 ftsvg">
              <div className="w-full ml-0 md:-ml-3 grid grid-rows-2 grid-flow-col gap-3">
                <div className="row-span-3">
                  <img
                    className="mx-auto md:mr-0"
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1722856308/vantagecircle/career/Certification_Badge_MAY_2024-2025_6.png"
                    alt="Great Place to work"
                    width="56"
                    height="96"
                  />
                </div>
                <div className="col-span-2">
                  <img
                    className="opacity-70"
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_100/gatsbycms/uploads/2021/08/ISO-cert.svg"
                    alt="ISO Certified"
                    width="100"
                    height="43"
                  />
                </div>
                <div className="col-span-2">
                  <img
                    className="opacity-70"
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_98/v1629535048/gatsbycms/uploads/2021/08/GDPR.svg"
                    alt="GDPR EU"
                    width="98"
                    height="23"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const handleFooter = () => {
    if (showFooterContent) {
      if (pageLanguage === undefined || pageLanguage === "en") {
        return <FooterEn />
      } else return <FooterMultiLang pageLanguage={pageLanguage} />
    }
  }

  const handleBtmFooter = () => {
    return <FooterBtmEn pageLanguage={pageLanguage} />
  }

  return (
    <>
      {originalPathObj == "/start-free-trial/" ? null : (
        <footer
          className={
            showFooterContent ? `bg-indigo-100 vc-footer pt-10 md:pt-20` : ``
          }
        >
          {showFooterContent ? renderAppIcons() : null}
          {/* <div className="w-full 2xl:w-10/12">
              {handleFooter()}
              <div className="md:flex items-center justify-start md:justify-between lg:justify-start py-5 md:pb-10">
                <div className="">
                  <div className="w-full md:flex md:ml-auto md:mr-0 md:mb-4 mx-auto items-center flex-shrink-0 md:space-x-2">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.bargaintechnologies.vantagecircle&hl=en"
                      className="mr-2 group inline-flex py-2 p-3 rounded items-center border border-gray-300 mb-1 md:mb-0"
                    >
                      <svg
                        fill="#bcbcc7"
                        className="w-6 h-6"
                        viewBox="0 0 512 512"
                      >
                        <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
                      </svg>
                      <span className="ml-4 flex items-start flex-col leading-none">
                        <span className="text-xs mb-1 text-gray-300 group-hover:text-gray-100 duration-700">
                          <Trans>FREE DOWNLOAD</Trans>
                        </span>
                        <span className="title-font font-bold text-gray-300 group-hover:text-gray-100 duration-700">
                          Google Play
                        </span>
                      </span>
                    </a>
                    <a
                      href="https://apps.apple.com/in/app/vantage-circle/id869311123?ign-mpt=uo%3D4"
                      className="group inline-flex py-2 p-3 rounded items-center border border-gray-300"
                    >
                      <svg
                        fill="#bcbcc7"
                        className="w-6 h-6"
                        viewBox="0 0 305 305"
                      >
                        <path d="M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z"></path>
                        <path d="M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z"></path>
                      </svg>
                      <span className="ml-4 flex items-start flex-col leading-none">
                        <span className="text-xs mb-1 text-gray-300 group-hover:text-gray-100 duration-700">
                          <Trans>FREE DOWNLOAD</Trans>
                        </span>
                        <span className="title-font font-bold text-gray-300 group-hover:text-gray-100 duration-700">
                          App Store
                        </span>
                      </span>
                    </a>
                  </div>
                  <div className="flex social-icons -ml-3 mb-4">
                    <a
                      href="https://www.facebook.com/vantagecircle/"
                      rel="noopener noreferrer"
                      className="w-8 h-8 flex items-end justify-center mr-2"
                    >
                      <svg
                        className="flex-1 mx-auto"
                        height="20"
                        viewBox="0 0 24 24"
                        width="20"
                      >
                        <path
                          d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"
                          fill="#bcbcc7"
                        ></path>
                      </svg>
                    </a>
                    <a
                      href="https://twitter.com/VantageCircle/"
                      rel="noopener noreferrer"
                      className="w-8 h-8 flex items-end justify-center mr-2"
                    >
                      <svg
                        className="flex-1 mx-auto"
                        height="20"
                        viewBox="0 0 24 24"
                        width="20"
                      >
                        <path
                          d="m21.534 7.113c.976-.693 1.797-1.558 2.466-2.554v-.001c-.893.391-1.843.651-2.835.777 1.02-.609 1.799-1.566 2.165-2.719-.951.567-2.001.967-3.12 1.191-.903-.962-2.19-1.557-3.594-1.557-2.724 0-4.917 2.211-4.917 4.921 0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.142-5.147-.424.737-.674 1.58-.674 2.487 0 1.704.877 3.214 2.186 4.089-.791-.015-1.566-.245-2.223-.606v.054c0 2.391 1.705 4.377 3.942 4.835-.401.11-.837.162-1.29.162-.315 0-.633-.018-.931-.084.637 1.948 2.447 3.381 4.597 3.428-1.674 1.309-3.8 2.098-6.101 2.098-.403 0-.79-.018-1.177-.067 2.18 1.405 4.762 2.208 7.548 2.208 8.683 0 14.342-7.244 13.986-14.637z"
                          fill="#bcbcc7"
                        ></path>
                      </svg>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/vantage-circle/"
                      rel="noopener noreferrer"
                      className="w-8 h-8 flex items-end justify-center mr-2"
                    >
                      <svg
                        className="flex-1 mx-auto"
                        height="20"
                        viewBox="0 0 24 24"
                        width="20"
                      >
                        <path
                          d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z"
                          fill="#bcbcc7"
                        ></path>
                        <path
                          d="m.396 7.977h4.976v16.023h-4.976z"
                          fill="#bcbcc7"
                        ></path>
                        <path
                          d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z"
                          fill="#bcbcc7"
                        ></path>
                      </svg>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCzb6J4NEmhuGpj_-u2kz7aw/"
                      rel="noopener noreferrer"
                      className="w-8 h-8 flex items-end justify-center mr-2"
                    >
                      <svg
                        className="flex-1 mx-auto"
                        height="20"
                        viewBox="0 0 24 24"
                        width="20"
                      >
                        <path
                          d="m23.469 5.929.03.196c-.29-1.029-1.073-1.823-2.068-2.112l-.021-.005c-1.871-.508-9.4-.508-9.4-.508s-7.51-.01-9.4.508c-1.014.294-1.798 1.088-2.083 2.096l-.005.021c-.699 3.651-.704 8.038.031 11.947l-.031-.198c.29 1.029 1.073 1.823 2.068 2.112l.021.005c1.869.509 9.4.509 9.4.509s7.509 0 9.4-.509c1.015-.294 1.799-1.088 2.084-2.096l.005-.021c.318-1.698.5-3.652.5-5.648 0-.073 0-.147-.001-.221.001-.068.001-.149.001-.23 0-1.997-.182-3.951-.531-5.846zm-13.861 9.722v-7.293l6.266 3.652z"
                          fill="#bcbcc7"
                        ></path>
                      </svg>
                    </a>
                    <a
                      href="https://www.pinterest.com/vantagecircle/"
                      rel="noopener noreferrer"
                      className="w-8 h-8 flex items-end justify-center mr-2"
                    >
                      <svg
                        className="flex-1 mx-auto"
                        height="20"
                        viewBox="0 0 24 24"
                        width="20"
                      >
                        <path
                          d="m12.326 0c-6.579.001-10.076 4.216-10.076 8.812 0 2.131 1.191 4.79 3.098 5.633.544.245.472-.054.94-1.844.037-.149.018-.278-.102-.417-2.726-3.153-.532-9.635 5.751-9.635 9.093 0 7.394 12.582 1.582 12.582-1.498 0-2.614-1.176-2.261-2.631.428-1.733 1.266-3.596 1.266-4.845 0-3.148-4.69-2.681-4.69 1.49 0 1.289.456 2.159.456 2.159s-1.509 6.096-1.789 7.235c-.474 1.928.064 5.049.111 5.318.029.148.195.195.288.073.149-.195 1.973-2.797 2.484-4.678.186-.685.949-3.465.949-3.465.503.908 1.953 1.668 3.498 1.668 4.596 0 7.918-4.04 7.918-9.053-.016-4.806-4.129-8.402-9.423-8.402z"
                          fill="#bcbcc7"
                        ></path>
                      </svg>
                    </a>
                    <a
                      href="https://www.instagram.com/vantagecircle/"
                      rel="noopener noreferrer"
                      className="w-8 h-8 flex items-end justify-center"
                    >
                      <svg
                        className="flex-1 mx-auto"
                        height="20"
                        viewBox="0 0 24 24"
                        width="20"
                      >
                        <path
                          d="m12.004 5.838c-3.403 0-6.158 2.758-6.158 6.158 0 3.403 2.758 6.158 6.158 6.158 3.403 0 6.158-2.758 6.158-6.158 0-3.403-2.758-6.158-6.158-6.158zm0 10.155c-2.209 0-3.997-1.789-3.997-3.997s1.789-3.997 3.997-3.997 3.997 1.789 3.997 3.997c.001 2.208-1.788 3.997-3.997 3.997z"
                          fill="#bcbcc7"
                        ></path>
                        <path
                          d="m16.948.076c-2.208-.103-7.677-.098-9.887 0-1.942.091-3.655.56-5.036 1.941-2.308 2.308-2.013 5.418-2.013 9.979 0 4.668-.26 7.706 2.013 9.979 2.317 2.316 5.472 2.013 9.979 2.013 4.624 0 6.22.003 7.855-.63 2.223-.863 3.901-2.85 4.065-6.419.104-2.209.098-7.677 0-9.887-.198-4.213-2.459-6.768-6.976-6.976zm3.495 20.372c-1.513 1.513-3.612 1.378-8.468 1.378-5 0-7.005.074-8.468-1.393-1.685-1.677-1.38-4.37-1.38-8.453 0-5.525-.567-9.504 4.978-9.788 1.274-.045 1.649-.06 4.856-.06l.045.03c5.329 0 9.51-.558 9.761 4.986.057 1.265.07 1.645.07 4.847-.001 4.942.093 6.959-1.394 8.453z"
                          fill="#bcbcc7"
                        ></path>
                        <circle
                          cx="18.406"
                          cy="5.595"
                          r="1.439"
                          fill="#bcbcc7"
                        ></circle>
                      </svg>
                    </a>
                  </div>
                </div>

                <div className="w-1/2 md:w-3/12 ftsvg">
                  <div className="w-full ml-0 md:-ml-3 grid grid-rows-2 grid-flow-col gap-3">
                    <div className="row-span-3">
                      <img
                        className="mx-auto md:mr-0"
                        loading="lazy"
                        src="https://res.cloudinary.com/vantagecircle/image/upload/w_180/gatsbycms/uploads/2023/08/Great_place_to_work_23_24-1.png"
                        alt="Great Place to work"
                        width="56"
                        height="96"
                      />
                    </div>
                    <div className="col-span-2">
                      <img
                        className="opacity-70"
                        loading="lazy"
                        src="https://res.cloudinary.com/vantagecircle/image/upload/w_100/gatsbycms/uploads/2021/08/ISO-cert.svg"
                        alt="ISO Certified"
                        width="100"
                        height="43"
                      />
                    </div>
                    <div className="col-span-2">
                      <img
                        className="opacity-70"
                        loading="lazy"
                        src="https://res.cloudinary.com/vantagecircle/image/upload/w_98/v1629535048/gatsbycms/uploads/2021/08/GDPR.svg"
                        alt="GDPR EU"
                        width="98"
                        height="23"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

          {/* <div className="w-full md:w-3/12 md:pl-14 md:border-l border-gray-300 no-br">
              <div className="text-md text-white mt-5 md:mt-0 md:mb-6">
                <Trans pageLanguage={pageLanguage}>Locations</Trans>
              </div>
              <div className="flex mb-5 md:-mt-1">
                <div className="mt-1 mr-2">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_30/gatsbycms/uploads/2021/06/US.png"
                    alt="US Flag"
                    width="30"
                    height="20"
                    loading="lazy"
                  />
                </div>
                <div className="block text-gray-300 text-base duration-700 leading-6">
                  <strong>Texas</strong>, USA
  
                </div>
              </div>
              <div className="flex mb-5">
                <div className="mt-1 mr-2">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_30/v1623678881/gatsbycms/uploads/2021/06/AUS.png"
                    alt="Australia Flag"
                    width="30"
                    height="20"
                    loading="lazy"
                  />
                </div>
                <div className="block text-gray-300 text-base duration-700 leading-6">
                  <strong>New South Wales</strong>, AUS
                 
                </div>
              </div>
              <div className="flex mb-5">
                <div className="mt-1 mr-2">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_30/gatsbycms/uploads/2021/06/IND.png"
                    alt="India Flag"
                    width="30"
                    height="20"
                    loading="lazy"
                  />
                </div>
                <div className="block text-gray-300 text-base duration-700 leading-6">
                  <strong>New Delhi</strong>, India
                  C1-1151, Vasant Kunj,<br/>
                                New Delhi – 110070
                </div>
              </div>
              <div className="flex mb-5">
                <div className="mt-1 mr-2">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/09/canada-footer.png"
                    alt="Canada Flag"
                    width="30"
                    height="20"
                    loading="lazy"
                  />
                </div>
                <div className="block text-gray-300 text-base duration-700 leading-6">
                  <strong>Alberta</strong>, Canada
                </div>
              </div>
              <div className="flex mb-5">
                <div className="mt-1 mr-2">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/09/netherland-footer.png"
                    alt="The Netherlands Flag"
                    width="30"
                    height="20"
                    loading="lazy"
                  />
                </div>
                <div className="block text-gray-300 text-base duration-700 leading-6">
                  <strong>Amstelveen</strong>, Netherlands
                </div>
              </div>
              <div className="flex">
                <div className="mt-1 mr-2">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/w_30/gatsbycms/uploads/2021/06/IND.png"
                    alt="India Flag"
                    width="30"
                    height="20"
                  />
                </div>
                <div className="block text-gray-300 text-base duration-700 leading-6">
                  <strong>Guwahati</strong>, India
                </div>
              </div>
            </div> */}

          <div className="footer_bottom">
            <div className="max-w-7xl mx-auto">{handleBtmFooter()}</div>
          </div>
        </footer>
      )}

      {/*CONSENT POPUP GDPR */}
      {<CookieConsent pageLanguage={pageLanguage} />}
    </>
  )
}

export default Footer
