import React, { useEffect } from "react"
import { bottomFooterLinks } from "../../../static/footerLinks"
// import { Trans } from "react-i18next"
import { useLanguages } from "../../hooks/useLanguages"
import Trans from '../Trans'
import { Link } from "gatsby"

const FooterBtmEn = ({pageLanguage}) => {
  const [ currLanguage ] = useLanguages()

  const getUrl = url => {
    if(pageLanguage === undefined) return
    return bottomFooterLinks[pageLanguage][url]
  }

  return (
    <>
      <div className="text-center md:text-left md:flex pb-5 px-3 xl:px-0 2xl:px-3 m-auto pt-5 text-gray-300 text-sm items-center md:justify-start justify-center">
        <div className="w-full md:w-5/12">
          {/* ©{" "} */}
          <a className="text-white" href={getUrl("vantage-circle")}>
            {/* Vantage Circle */}
          </a>
          <Trans pageLanguage={pageLanguage}>All rights reserved.</Trans>
        </div>
        <div className="w-full md:w-7/12 md:inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-end">
          <a
              className="inline-block text-gray-300 hover:text-gray-100 text-sm duration-700 cursor-pointer"
              href={getUrl("dpdp")}
            >
              <Trans pageLanguage={pageLanguage}>DPDP</Trans>
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
          <a
            className="inline-block text-gray-300 hover:text-gray-100 text-sm duration-700 cursor-pointer"
            href={getUrl("gdpr")}
          >
            <Trans pageLanguage={pageLanguage}>GDPR</Trans>
          </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a
            className="inline-block text-gray-300 hover:text-gray-100 text-sm duration-700 cursor-pointer"
            href={getUrl("security-at-vantage-circle")}
          >
            <Trans pageLanguage={pageLanguage}>Security</Trans>
          </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a
            className="inline-block text-gray-300 hover:text-gray-100 text-sm duration-700 cursor-pointer"
            href={getUrl("terms-conditions")}
          >
            <Trans pageLanguage={pageLanguage}>Terms and Conditions</Trans>
          </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a
            className="inline-block text-gray-300 hover:text-gray-100 text-sm duration-700 cursor-pointer"
            href={getUrl("privacy-policy")}
          >
            <Trans pageLanguage={pageLanguage}>Privacy Policy</Trans>
          </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a
            className="inline-block text-gray-300 hover:text-gray-100 text-sm duration-700 cursor-pointer"
            href={getUrl("cookie-policy")}
          >
            <Trans pageLanguage={pageLanguage}>Cookie Policy</Trans>
          </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a
            className="inline-block text-gray-300 hover:text-gray-100 text-sm duration-700 cursor-pointer"
            href={getUrl("image-copyright")}
          >
            <Trans pageLanguage={pageLanguage}>Image Copyright</Trans>
          </a>
        </div>
      </div>
    </>
  )
}

export default FooterBtmEn
