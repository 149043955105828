import { useState, useEffect } from "react"
import axios from "axios"

export default function useGeoLocation() {
  const [currentCountry, setCurrentCountry] = useState({ country: "" })

  useEffect(() => {
    axios
      .get("https://geolocation-db.com/json/")
      .then(response => {
        let data = response.data
        setCurrentCountry({ country: data.country_name })
      })
      .catch(error => {
        //console.log(error)
      })
  }, [])

  return [currentCountry]
}
